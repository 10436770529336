<template>
    <div>
        <form>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.name"
                        v-model="contact.name">Name</text-input>
                </column>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.surname"
                        v-model="contact.surname">
                        Last Name
                    </text-input>
                </column>
            </columns>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.email" v-model="contact.email">
                        Email Address
                    </text-input>
                </column>
                <column>
                    <text-input classes="is-medium is-rounded" v-model="contact.mobile" :error="$root.errors.mobile">
                        Mobile <small>(Optional)</small>
                    </text-input>
                </column>
                <column>
                    <text-input classes="is-medium is-rounded" v-model="contact.position" :error="$root.errors.position">
                        Position <small>(Optional)</small>
                    </text-input>
                </column>
            </columns>

            <columns>
                <column>
                    <h4 class="title is-4">Notification Preferences</h4>
                </column>
            </columns>

            <columns>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.work_order_opened">
                        New Work Order Opened
                    </switch-input>
                </column>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.work_order_completion">
                        Work Order Completion Report
                    </switch-input>
                </column>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.checklist_completion">
                        Checklist Completion Report
                    </switch-input>
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button 
                        :working="updating_contact"
                        @submit="updateContact" 
                        class="is-rounded">
                        Save
                    </submit-button>
                </column>
            </columns>
        </form>
    </div>
</template>
<script>
import { location as backend } from '@/api'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        updating_contact: false,
        contact: {
            name: '',
            surname: '',
            email: '',
            mobile: '',
            position: '',
            notification_preferences: {
                checklist_completion: false,
                work_order_completion: false,
                work_order_opened: false
            }
        }
    }),

    beforeCreate() {
        backend.loadContact({
            location: this.$route.params.location,
            contact: this.$route.params.contact
        }, ({data}) => {
            this.contact = data
            this.loading = false
        }, () => this.loading = false)
    },

    methods: {
        updateContact() {
            this.updating_contact = true
            backend.updateContact(this.contact, () => {
                this.updating_contact = false
                this.$toast.success('Saved')
            }, () => this.updating_contact = false)
        }
    },

    computed: {
        ...mapGetters('location', [
            'location'
        ])
    },

}
</script>